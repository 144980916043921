import { Buffer } from "buffer";

export const pagesWithNoBookDemo = [
    '/sign-up',
    '/brf',
    '/speedledger',
    '/payment',
    '/webbyra',
    '/book-demo',
    '/categories/bokningssystem-for-smarta-verkstader'
];

export const pagesWithNoChat = [
    '/payment'
]

const poll = (time: number) => {
    return new Promise((resolve) => {
        const intervalId = setInterval(() => {
            if(window.BOKAMERA && typeof window !== 'undefined' && 'restartEmbedBookingApp' in window) {
                window.restartEmbedBookingApp();
                resolve(true);
                clearInterval(intervalId);
            } else if (!window.BOKAMERA) {
                resolve(true);
            }
        }, time);
        
    })
}

export const embedWidget = (configuration: any) => {
    poll(50).then(() => {
        window.BOKAMERA = { configuration };
        
        const script = document.createElement('script');
        script.src = import.meta.env.VITE_EMBED_BOOKING_SCRIPT_SRC;
        document.head.appendChild(script);
    });    
}

export const encryptSymmetric = async (plaintext: string, key: string) => {
    // create a random 96-bit initialization vector (IV)
    const iv = crypto.getRandomValues(new Uint8Array(12));
  
    // encode the text you want to encrypt
    const encodedPlaintext = new TextEncoder().encode(plaintext);
  
    // prepare the secret key for encryption
    const secretKey = await crypto.subtle.importKey('raw', Buffer.from(key, 'base64'), {
        name: 'AES-CBC',
        length: 256
    }, true, ['encrypt', 'decrypt']);
  
    // encrypt the text with the secret key
    const ciphertext = await crypto.subtle.encrypt({
        name: 'AES-CBC',
        iv
    }, secretKey, encodedPlaintext);
    
    // return the encrypted text "ciphertext" and the IV
    // encoded in base64
    return ({
        ciphertext: Buffer.from(ciphertext).toString('base64'),
        iv: Buffer.from(iv).toString('base64')
    });
  }

export const decryptSymmetric = async (ciphertext: string, iv: string, key: string) => {
    // prepare the secret key
    const secretKey = await crypto.subtle.importKey(
        'raw',
        Buffer.from(key, 'base64'), 
        {
        name: 'AES-CBC',
        length: 256
    }, true, ['encrypt', 'decrypt']);
  
    // decrypt the encrypted text "ciphertext" with the secret key and IV
    const cleartext = await crypto.subtle.decrypt({
        name: 'AES-CBC',
        iv: Buffer.from(iv, 'base64'),
    }, secretKey, Buffer.from(ciphertext, 'base64'));
  
    // decode the text and return it
    return new TextDecoder().decode(cleartext);
  }